<template>
  <div class="cartContent no-item-cart">
    <p>Unauthorized - User is already logged in another device.</p>
    <button class="univ-btn w-10 mb-4" @click="redirectToHome">
      Go to Home
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToHome() {
      this.$route.meta.hideHeaderFooter = false;
      this.$router.push("/");
    },
  },
};
</script>
